<template>
  <!-- INI YANG DI CARI TAHU -->
  <div class="container_no_auth">
    <div class="child_no_auth">
      <v-row class="pa-3">
        <v-col cols="12" md="4">
          <v-text-field
            solo
            dense
            type="search"
            autocomplete="off"
            label="Search Article"
            rounded
            append-icon="mdi-magnify"
            hide-details
            v-model="find"
            background-color="white"
            @keyup="fetch"
          ></v-text-field>
        </v-col>
      </v-row>
      <!-- <h2>All Articles</h2> -->
      <v-divider></v-divider>
      <div v-if="!loading">
        <section v-if="article">
          <v-row>
            <v-col
              md="3"
              lg="3"
              sm="6"
              xs="6"
              cols="12"
              v-for="(article, idx) in article.data"
              :key="idx"
            >
              <v-card
                class="radius-card artcle_card__ ma-2 pointer__"
                height="270px"
                content="Klik untuk melihat artikel"
                v-tippy="{
                  arrow: true,
                  arrowType: 'round',
                  animation: 'fade',
                  theme: 'light',
                  maxWidth: 200
                }"
                :to="`/public/article/${article.id}/view`"
              >
                <div class="temp_img__">
                  <img
                    :src="
                      `${env}/artikel/upload/${article.user_id}/${article.image}`
                    "
                    class="img-fit scale"
                    height="150px"
                    width="100%"
                    alt="cover"
                  />
                </div>
                <div class="px-2">
                  <h4 v-snip="1">{{ article.judul }}</h4>
                  <span v-snip="3" class="_isi" v-html="article.isi"> </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
          <section
            class="pa-2 d-flex justify-center"
            style="width: 100%"
            v-if="!article.data.length"
          >
            <div>
              <img src="../../assets/img/404.svg" height="100px" alt="404" />
              <h3 class="text-center">No Data Found!</h3>
            </div>
          </section>
        </section>
      </div>
      <div v-if="loading">
        <v-row>
          <v-col md="3" lg="3" sm="6" xs="6" v-for="i in 4" :key="i">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "publicArticle",
  data() {
    return {
      editArticle: false,
      item: null,
      find: "",
      loaidng: false
    };
  },
  computed: {
    ...mapState({
      article: state => state.library.article,
      loading: state => state.library.loading,
      env: state => state.API_URL,
      id: state => state.id,
      role: state => state.role
    })
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.loading = true;
      let data = { page: 1, limit: 30, find: this.find };
      this.$store.dispatch("library/publicArticle", data).then(() => {
        this.loading = true;
      });
    },
    viewTo(id) {
      this.$router.push(`/article/${id}/view`);
    }
  }
};
</script>

<style scoped>
.container_no_auth {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}
.child_no_auth {
  width: 80%;
}
._isi {
  line-height: 1.2;
}
.artcle_card__ {
  overflow: hidden;
}
.temp_img__ {
  overflow: hidden;
  min-height: 150px;
}
.scale {
  transition: 0.5s;
}
.scale:hover {
  transition: 0.5s;
  transform: scale(1.1);
}
.action__ {
  margin-top: -20px;
}
.pointer__ {
  cursor: pointer;
}
</style>
