<template>
  <div>
    <Nav />
    <HeroCover
      v-bind:title="title"
      v-bind:cover="require('../../assets/img/book_lover.svg')"
    />
    <v-card class="_wave pa-3">
      <v-tabs v-model="tab" class="mb-5 rounded" color="purple" grow>
        <v-tab href="#tab-1"><v-icon>mdi-book</v-icon><b>Article</b></v-tab>
        <v-tab href="#tab-2"
          ><v-icon>mdi-book-multiple</v-icon><b>E-book</b></v-tab
        >
      </v-tabs>
      <v-tabs-items v-model="tab" style="background: none">
        <v-tab-item value="tab-1">
          <AllArticle />
        </v-tab-item>
        <v-tab-item value="tab-2">
          <AllEbook />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import Nav from "../../components/General/Nav.vue";
import HeroCover from "../../components/_base/heroCover.vue";
import AllArticle from "../General/publicArticle";
import AllEbook from "../General/publicEbook";
export default {
  name: "caritahu",
  data() {
    return {
      isActive: false,
      tab: null,
      //
      title: "Temukan Literasi Piskologi Yang Valid dan Reliabel",
      category: [
        { name: "Psikologi Klinis" },
        { name: "Psikologi Pendidikan" },
        { name: "Psikologi Industri & Organisasi" }
      ]
    };
  },
  components: {
    HeroCover,
    Nav,
    AllArticle,
    AllEbook
  },
  methods: {}
};
</script>
<style scoped>
.cards {
  display: flex;
  justify-content: space-between;
  width: 450px;
  height: max-content;
}
.radius {
  height: 130px;
  width: 130px;
  border-radius: 50% !important;
}
p {
  margin-bottom: 0;
}
</style>
